import React from "react";
import { styled } from "@mui/styles";
import { ModalCore } from "../ui/modal";
import { Colors } from "../../../blocks/utilities/src/Colors";
import { Button } from "../ui/button";

interface Props {
  open: boolean;
  handleClose: () => void;
}

const StyledHeader = styled("h3")({
  fontSize: "1.5rem",
  fontWeight: 700,
  color: Colors.textPrimary,
  "@media (max-width: 768px)": {
    fontSize: "1.25rem",
  },
});

const commonStyles = {
  color: Colors.textPrimary,
  marginTop: "4px",
  fontSize: "1rem",
  lineHeight: "1.35",
  "@media (max-width: 768px)": {
    fontSize: "0.875rem",
  },
};

const StyledParagraph = styled("p")(commonStyles);

const StyledUl = styled("ul")(commonStyles);

const StyledLi = styled("li")(commonStyles);

const HorizontalLines = styled("div")({
  width: "100%",
  height: "1px",
  backgroundColor: "#E5E5E5",
  margin: "24px 0",
});

const ButtonContainer = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  gap: "16px",
  marginTop: "24px",
});

export const PrivacyPolicyModal = ({ open, handleClose }: Props) => {
  return (
    <ModalCore open={open} handleClose={handleClose}>
      <div>
        <StyledHeader>Privacy Policy</StyledHeader>
        <StyledParagraph>1. Information We Collect</StyledParagraph>
        <StyledParagraph>
          We may collect the following types of information:
        </StyledParagraph>
        <StyledUl>
          <p>a. Personal Information</p>
          <StyledUl>
            <StyledLi>Name</StyledLi>
            <StyledLi>Email address</StyledLi>
            <StyledLi>Phone number</StyledLi>
            <StyledLi>
              Payment information (processed securely through third-party
              providers)
            </StyledLi>
          </StyledUl>
          <p>b. Non-Personal Information</p>
          <StyledUl>
            <StyledLi>IP address</StyledLi>
            <StyledLi>Browser type</StyledLi>
            <StyledLi>Device information</StyledLi>
            <StyledLi>
              Usage data, such as pages visited and features used
            </StyledLi>
          </StyledUl>
          <p>c. User-Generated Content</p>
          <StyledUl>
            <StyledLi>Designs and projects created on the Platform</StyledLi>
            <StyledLi>Files or images uploaded by the user</StyledLi>
          </StyledUl>
        </StyledUl>

        <StyledParagraph>2. How We Use Your Information</StyledParagraph>
        <StyledParagraph>We use the collected information to:</StyledParagraph>
        <StyledUl>
          <StyledLi>Provide and improve our services</StyledLi>
          <StyledLi>Process payments and manage subscriptions</StyledLi>
          <StyledLi>Respond to customer support inquiries</StyledLi>
          <StyledLi>Personalize your experience</StyledLi>
          <StyledLi>
            Send promotional emails or updates (with your consent)
          </StyledLi>
          <StyledLi>Analyze usage trends to enhance our Platform</StyledLi>
        </StyledUl>

        <StyledParagraph>3. Sharing Your Information</StyledParagraph>
        <StyledParagraph>
          We do not sell your personal information. However, we may share your
          data in the following circumstances:
        </StyledParagraph>
        <StyledUl>
          <StyledLi>
            With Service Providers: To facilitate payment processing, analytics,
            or marketing services.
          </StyledLi>
          <StyledLi>
            For Legal Compliance: To comply with legal obligations, enforce our
            Terms, or protect our rights.
          </StyledLi>
          <StyledLi>
            With Your Consent: If you explicitly agree to share your information
            with third parties.
          </StyledLi>
        </StyledUl>

        <StyledParagraph>4. Cookies and Tracking Technologies</StyledParagraph>
        <StyledParagraph>
          We use cookies and similar tracking technologies to enhance your
          experience on the Platform. These include:
        </StyledParagraph>
        <StyledUl>
          <StyledLi>Essential Cookies: For core functionality.</StyledLi>
          <StyledLi>
            Analytics Cookies: To gather insights about user behavior.
          </StyledLi>
          <StyledLi>
            Marketing Cookies: To deliver tailored advertisements.
          </StyledLi>
        </StyledUl>
        <StyledParagraph>
          You can manage cookie preferences through your browser settings.
        </StyledParagraph>

        <StyledParagraph>5. Data Security</StyledParagraph>
        <StyledParagraph>
          We implement industry-standard security measures to protect your
          personal information, including encryption and secure servers.
          However, no method of transmission over the Internet is 100% secure,
          and we cannot guarantee absolute security.
        </StyledParagraph>

        <StyledParagraph>6. Your Rights</StyledParagraph>
        <StyledParagraph>
          Depending on your location, you may have the following rights:
        </StyledParagraph>
        <StyledUl>
          <StyledLi>
            Access: Request a copy of the personal data we hold about you.
          </StyledLi>
          <StyledLi>
            Correction: Update or correct inaccurate information.
          </StyledLi>
          <StyledLi>
            Deletion: Request the deletion of your data, subject to legal
            obligations.
          </StyledLi>
          <StyledLi>
            Opt-Out: Unsubscribe from marketing emails or disable cookies.
          </StyledLi>
        </StyledUl>
        <StyledParagraph>
          To exercise these rights, contact us at [Insert Contact Email].
        </StyledParagraph>

        <StyledParagraph>7. Third-Party Links</StyledParagraph>
        <StyledParagraph>
          Our Platform may contain links to third-party websites. We are not
          responsible for the privacy practices of these sites and encourage you
          to review their privacy policies.
        </StyledParagraph>


        <HorizontalLines />

        <ButtonContainer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Accept
          </Button>
        </ButtonContainer>
      </div>
    </ModalCore>
  );
};
