import React from "react";
import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";
import Slider from "react-slick";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  arrow: {
    width: "40px",
    height: "40px",
    backgroundColor: "#005C97",
    color: "#fff",
    padding: "6px",
    borderRadius: "50%",
    position: "absolute",
    top: "32%",
    zIndex: 10,
  },
  prevArrow: {
    left: "-0.9%",
  },
  nextArrow: {
    right: "0.5%",
  },
});

function SampleNextArrow(props: any) {
  const styles = useStyles();

  return (
    <ArrowForwardIos
      className={`${styles.arrow} ${styles.nextArrow}`}
      onClick={props.onClick}
    />
  );
}

function SamplePrevArrow(props: any) {
  const styles = useStyles();

  return (
    <ArrowBackIosNew
      className={`${styles.arrow} ${styles.prevArrow}`}
      onClick={props.onClick}
    />
  );
}

const commonSettings = {
  dots: false,
  swipeToSlide: true,
  slidesToScroll: 1,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
};

const barnSettings = {
  ...commonSettings,
  slidesToShow: 2,
};

const categorySettings = {
  ...commonSettings,
  slidesToShow: 5,
  responsive: [
    {
      breakpoint: 970,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

interface BaseSliderProps {
  children: React.ReactNode;
  type: "barn" | "category";
}

export const BaseSlider: React.FC<BaseSliderProps> = ({ children, type }) => {
  return (
    <Slider {...(type === "barn" ? barnSettings : categorySettings)}>
      {children}
    </Slider>
  );
};
