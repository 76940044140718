import React from "react";
import Popover from "@mui/material/Popover";
import TuneIcon from "@mui/icons-material/Tune";
import { Checkbox } from "../../ui/input/checkbox";
import { Colors } from "../../../../blocks/utilities/src/Colors";
import { makeStyles } from "@mui/styles";
// @ts-ignore
import {
  BARN_TYPE,
  PROJECT_STATUSES,
  PROJECT_TYPE,
} from "../../constants/filters";

const useStyles = makeStyles({
  filterBlock: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
    marginTop: "8px",
    cursor: "pointer",
  },
  filterText: {
    fontSize: "14px",
    fontWeight: 700,
    color: Colors.btnPrimary,
  },
  filterHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  filterHeaderText: {
    fontSize: "14px",
    fontWeight: 700,
    color: Colors.textTertiary,
    marginTop: "12px",
  },
  filterTypeHeader: {
    textTransform: "uppercase",
    fontSize: "14px",
    color: "#83889E",
    marginTop: "12px"
  },
  clearBtn: {
    border: "none",
    outline: "none",
    fontWeight: 700,
    color: Colors.btnPrimary,
    backgroundColor: "transparent",
  },
  filterItemChoice: {
    fontSize: "14px",
    color: Colors.textPrimary,
  }
});

export const ProjectFilterDropdown = () => {
  const styles = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <div onClick={handleClick} className={styles.filterBlock}>
        <TuneIcon fontSize="small" style={{ color: Colors.btnPrimary }} />
        <p className={styles.filterText}>Filter</p>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div style={{padding: "16px"}}>
          <div className={styles.filterHeader}>
            <h4 style={{fontSize: "14px", fontWeight: 700, color: Colors.textTertiary}}>Filter</h4>
            <button className={styles.clearBtn} onClick={() => {}}>
              Clear all
            </button>
          </div>
          {/* Status */}
          <p className={styles.filterTypeHeader}>Status</p>
          {/* map all options with text and checkbox */}
          {PROJECT_STATUSES.map((status) => (
            <div key={status.key} className={styles.filterBlock}>
              <Checkbox />
              <p className={styles.filterItemChoice}>{status.title}</p>
            </div>
          ))}
          {/* Barn type */}
          <p className={styles.filterTypeHeader}>Barn type</p>
          {BARN_TYPE.map((type) => (
            <div key={type.key} className={styles.filterBlock}>
              <Checkbox />
              <p className={styles.filterItemChoice}>{type.title}</p>
            </div>
          ))}
          {/* Project type */}
          <p className={styles.filterTypeHeader}>Project type</p>
          {PROJECT_TYPE.map((type) => (
            <div key={type.key} className={styles.filterBlock}>
              <Checkbox />
              <p className={styles.filterItemChoice}>{type.title}</p>
            </div>
          ))}
        </div>
      </Popover>
    </div>
  );
};
