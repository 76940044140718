import React from "react";
// Customizable Area Start
// Customizable Area End
import ProjectTableViewController, {
  Props,
} from "./ProjectTableViewController.web";
import ProjectTable from "../../../components/src/dashboard/ProjectTable";
import { styled } from "@mui/styles";
import { Colors } from "../../utilities/src/Colors";
import { ProjectFilterDropdown } from "../../../components/src/dashboard/dropdown/ProjectFilterDropdown";

const SectionHeadingContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "24px",
});

const SectionHeading = styled("h3")({
  fontSize: "12px",
  color: Colors.textTertiary,
  fontWeight: 600,
  textTransform: "uppercase",
});

export default class ProjectTableView extends ProjectTableViewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    return (
      <div>
        <SectionHeadingContainer>
          <SectionHeading>projects</SectionHeading>
          <ProjectFilterDropdown />
        </SectionHeadingContainer>
        <ProjectTable />
      </div>
    );
    // Customizable Area End
  }
}
