
// Customizable Area Start
import React from "react";
import {ErrorMessage, Form, Formik} from "formik";
import {loginValidationSchema} from "../../../components/src/email-account-registration/types";
import {styled} from "@mui/styles";

import EmailAccountLoginController, {Props} from "./EmailAccountLoginController";
import {Colors} from "../../utilities/src/Colors";
import {Label} from "../../../components/src/ui/label";
import {Input} from "../../../components/src/ui/input";
import {Checkbox} from "../../../components/src/ui/input/checkbox";
import {Button} from "../../../components/src/ui/button";
import { Spinner } from "../../../components/src/ui/spinner";
import {Link} from "react-router-dom";
import {cover, coverMobile, logoMobile} from "./assets";

// Customizable Area End

const StyledLoginPage = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: Colors.backgroundLight,
  "@media (max-width: 768px)": {
    flexDirection: "column",
    height: "100vh",
    backgroundImage: `url(${coverMobile})`,
    backgroundSize: "cover"
  }
});

const StyledImageContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "50%"
});

const StyledCoverImage = styled("img")({
  width: "100%",
  height: "100vh",
  objectFit: "cover",
  borderBottomLeftRadius: "64px",
  "@media (max-width: 768px)": {
    display: "none"
  }
});

const StyledLogoMobile = styled("img")({
  display: "none",
  "@media (max-width: 768px)": {
    display: "block",
    width: "200px",
    marginBottom: "30px"
  }
});

const StyledTopContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "24px"
});

const StyledTitle = styled("h2")({
  fontSize: "24px",
  fontWeight: "bold",
  color: Colors.textPrimary,
  marginBottom: "24px"
});

const StyledDescription = styled("p")({
  color: Colors.textSecondary,
  fontSize: "16px",
  "@media (max-width: 768px)": {
    textAlign: "center"
  }
});

const StyledFormContainer = styled("div")({
  flex: 1,
  padding: "0 100px",
  "@media (max-width: 768px)": {
    zIndex: 10,
    backgroundColor: Colors.white,
    margin: "0 10px",
    padding: "12px 24px",
    flex: 0,
    borderRadius: "8px",
    borderBottomRightRadius: '32px'
  }
});

const StyledFormGroup = styled("div")({
  marginBottom: "24px",
  display: "flex",
  flexDirection: "column"
});

const StyledCheckboxContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "16px"
});


export default class EmailAccountLogin extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { isSubmitting, errorMsg } = this.state
    return (
      <StyledLoginPage>
        <StyledLogoMobile src={logoMobile} alt="logo mobile"/>
        <StyledFormContainer>
          <StyledTopContainer>
            <StyledTitle>Login</StyledTitle>
            <StyledDescription>
              We'll send you a verification code you can use to Login
            </StyledDescription>
          </StyledTopContainer>
          <Formik
            initialValues={{
              email: "",
              password: ""
            }}
            validationSchema={loginValidationSchema}
            onSubmit={this.handleLogin}
            data-test-id="login-formik"
          >
            {({ errors, values, touched, handleChange, handleBlur  }) => (
              <Form>
                <StyledFormGroup>
                  <Label htmlFor="email">Email</Label>
                  <Input
                    type="text"
                    name="email"
                    id="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.email && touched.email}
                    placeholder="youremail@gmail.com"
                  />
                  <ErrorMessage
                    data-test-id="error-message"
                    name="email"
                    component="div"
                    render={msg => <div style={styles.formError}>{msg}</div>}
                  />
                </StyledFormGroup>

                <StyledFormGroup>
                  <Label htmlFor="password">Password</Label>
                  <Input
                    name="password"
                    id="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="********"
                    isInvalid={!!errors.password && touched.password}
                    showToggle
                  />
                  <ErrorMessage
                    data-test-id="error-message"
                    name="password"
                    component="div"
                    render={msg => <div style={styles.formError}>{msg}</div>}
                  />
                </StyledFormGroup>

                <StyledCheckboxContainer>
                  <div
                    style={{
                      display: "flex",
                      gap: "4px",
                      alignItems: "center"
                    }}
                  >
                    <Checkbox onChange={this.handleCheckboxChange} />
                    <p style={{color: "#0F172A"}}>Remember me</p>
                  </div>
                  <Link
                    to="/forgot-password"
                    style={{
                      color: Colors.btnPrimary,
                      fontWeight: 600,
                      textDecoration: "none"
                    }}
                  >
                    Forgot password
                  </Link>
                </StyledCheckboxContainer>

                {errorMsg ? <p style={styles.formError}>{errorMsg}</p> : null}

                <Button variant="primary" type="submit" style={{width: '100%'}} disabled={isSubmitting}>
                  {isSubmitting ? <Spinner height="20px" width="20px" /> : "Log in"}
                </Button>
              </Form>
            )}
          </Formik>

          <p style={{textAlign: "center"}}>
            Don't have an account?{" "}
            <Link
              to={"/sign-up"}
              style={{
                color: Colors.btnPrimary,
                cursor: "pointer",
                fontWeight: 700,
                textDecoration: "none"
              }}
            >
              Sign up
            </Link>
          </p>

          <div
            style={{textAlign: "center", color: "#334155", marginTop: "80px"}}
          >
            <p>Have trouble logging in?</p>
            <p>Please call (888) 373-6322 for additional assistance.</p>
          </div>
        </StyledFormContainer>

        <StyledImageContainer>
          <StyledCoverImage src={cover} alt="Seneca Dairy Systems"/>
        </StyledImageContainer>
      </StyledLoginPage>
    );
  }
}

const styles = {
  formError: {
    color: Colors.error,
    fontSize: "12px",
    marginTop: "4px"
  }
};
