import { forgotPasswordValidationSchema } from "./types";
import { ErrorMessage, Form, Formik } from "formik";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { Link } from "react-router-dom";
import { Colors } from "../../../blocks/utilities/src/Colors";
import React from "react";
import { styled } from "@mui/styles";
import { Spinner } from "../ui/spinner";

const StyledFormGroup = styled("div")({
  marginBottom: "24px",
  display: "flex",
  flexDirection: "column",
});

const styles = {
  formError: {
    color: Colors.error,
    fontSize: "12px",
    marginTop: "4px",
  },
};

interface EmailVerificationProps {
  handleVerifyEmail: ({ email }: { email: string }) => void;
  isSubmitting: boolean;
  error: string;
}

export const EmailVerificationForm = ({
  handleVerifyEmail,
  isSubmitting,
  error,
}: EmailVerificationProps) => {
  return (
    <Formik
      initialValues={{
        email: "",
      }}
      validationSchema={forgotPasswordValidationSchema}
      onSubmit={handleVerifyEmail}
    >
      {({ errors, values, handleChange, handleBlur }) => (
        <Form>
          <StyledFormGroup>
            <Label htmlFor="email">Your email</Label>
            <Input
              type="text"
              name="email"
              id="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={!!errors.email}
              placeholder="youremail@gmail.com"
            />
            <ErrorMessage
              data-test-id="error-message"
              name="email"
              component="div"
              render={(msg) => <div style={styles.formError}>{msg}</div>}
            />
          </StyledFormGroup>

          {error ? <p style={styles.formError}>{error}</p> : null}

          <Button
            variant="primary"
            type="submit"
            style={{ width: "100%" }}
            disabled={isSubmitting}
          >
            {isSubmitting ? <Spinner height="20px" width="20px" /> : "Reset"}
          </Button>

          <Link
            to="/login"
            style={{
              display: "block",
              color: Colors.btnPrimary,
              fontWeight: 600,
              textDecoration: "none",
              marginTop: "24px",
              textAlign: "center",
            }}
          >
            Back to Log in
          </Link>
        </Form>
      )}
    </Formik>
  );
};
