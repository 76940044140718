import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import Pagination from '@mui/material/Pagination';
import { visuallyHidden } from "@mui/utils";
import { Badge } from "../ui/badge";
import { Project, Order, HeadCell, EnhancedTableProps } from "./types";
import { Colors } from "../../../blocks/utilities/src/Colors";
import ProjectDropdownMenu from "./ProjectDropdownMenu";
// @ts-ignore
import { getComparator } from "./utils";

function createProject(
  id: number,
  projectType: string,
  clientName: string,
  costEst: number,
  createdDate: string,
  status: string,
  key: "sent" | "in_progress" | "completed"
): Project {
  return {
    id,
    projectType,
    clientName,
    costEst,
    createdDate,
    status,
    key,
  };
}

const rows: Project[] = [
  createProject(
    1,
    "Equipment",
    "Andrew Lloyd",
    132543.0,
    "12-19-2024",
    "IN PROGRESS",
    "in_progress"
  ),
  createProject(
    2,
    "4-Row barn",
    "Mary Johnson",
    132543.0,
    "12-09-2024",
    "IN PROGRESS",
    "in_progress"
  ),
  createProject(
    3,
    "4-Row barn",
    "Ava Brown",
    132543.0,
    "12-03-2024",
    "COMPLETED",
    "completed"
  ),
  createProject(
    4,
    "Equipment",
    "William Davis",
    132543.0,
    "11-25-2024",
    "SENT",
    "sent"
  ),
  createProject(
    5,
    "4-Row barn",
    "Sophia Wilson",
    132543.0,
    "11-20-2024",
    "SENT",
    "sent"
  ),
  createProject(
    6,
    "Equipment",
    "Mary Johnson",
    132543.0,
    "12-09-2024",
    "IN PROGRESS",
    "in_progress"
  ),
  createProject(
    7,
    "4-Row barn",
    "Sophia Wilson",
    132543.0,
    "11-20-2024",
    "COMPLETED",
    "completed"
  ),
];

const headCells: readonly HeadCell[] = [
  {
    id: "projectType",
    numeric: false,
    disablePadding: true,
    label: "Project Type",
  },
  {
    id: "clientName",
    numeric: false,
    disablePadding: false,
    label: "Client Name",
  },
  {
    id: "costEst",
    numeric: false,
    disablePadding: false,
    label: "Cost Est",
  },
  {
    id: "createdDate",
    numeric: false,
    disablePadding: false,
    label: "Created Date",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
];

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Project) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            style={{
              color: Colors.btnPrimary,
              fontWeight: 700,
              paddingLeft: "24px",
              fontFamily: "Inter",
            }}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              style={{ color: Colors.btnPrimary, fontWeight: 700 }}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell style={{ color: Colors.btnPrimary, fontWeight: 700 }}>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

const StyledTableCell = ({ children }: { children: React.ReactNode }) => (
  <TableCell
    style={{
      paddingLeft: "24px",
      fontFamily: "Inter",
      color: Colors.textPrimary,
      flex: 1,
    }}
  >
    {children}
  </TableCell>
);

export default function ProjectTable() {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Project>("costEst");
  const [selected, setSelected] = React.useState<readonly number[]>([]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Project
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const visibleRows = React.useMemo(
    () => [...rows].sort(getComparator(order, orderBy)),
    [order, orderBy]
  );

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            stickyHeader
            aria-labelledby="tableTitle"
            size="medium"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = selected.includes(row.id);

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{ cursor: "pointer" }}
                  >
                    <StyledTableCell>{row.projectType}</StyledTableCell>
                    <StyledTableCell>{row.clientName}</StyledTableCell>
                    <StyledTableCell>
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(row.costEst)}
                    </StyledTableCell>
                    <StyledTableCell>{row.createdDate}</StyledTableCell>
                    <StyledTableCell>
                      <Badge label={row.status} variant={row.key} />
                    </StyledTableCell>
                    <TableCell style={{width: '100px'}}>
                      <ProjectDropdownMenu />
                    </TableCell>
                  </TableRow>
                );
              })}
              <TableRow>
                <TableCell colSpan={5} style={{ justifyContent: "center" }}>
                  <div style={{display: "flex", justifyContent: "center"}}>
                    <Pagination count={rows.length / 7} color="primary" />
                  </div>
                </TableCell>
                <TableCell style={{ textAlign: "right" }}>
                 <p style={{fontSize: '14px', color: Colors.textTertiary}}>
                   1-7 of {rows.length} results
                 </p>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}
