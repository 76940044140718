import React from "react";

// Customizable Area Start
import { ErrorMessage, Form, Formik } from "formik";
import { signUpValidationSchema } from "../../../components/src/email-account-registration/types";
import { styled } from "@mui/styles";

import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";
import { Colors } from "../../utilities/src/Colors";
import { Label } from "../../../components/src/ui/label";
import { Input } from "../../../components/src/ui/input";
import { Checkbox } from "../../../components/src/ui/input/checkbox";
import { Button } from "../../../components/src/ui/button";
import { Spinner } from "../../../components/src/ui/spinner";
import { Link } from "react-router-dom";
import { cover, coverMobile, logoMobile } from "./assets";
import { TermsConditionsModal } from "../../../components/src/email-account-registration/TermsConditionsModal";
import { PrivacyPolicyModal } from "../../../components/src/email-account-registration/PrivacyPolicyModal";

// Customizable Area End

const StyledSignUpPage = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: Colors.backgroundLight,
  "@media (max-width: 768px)": {
    flexDirection: "column",
    height: "100vh",
    backgroundImage: `url(${coverMobile})`,
    backgroundSize: "cover"
  }
});

const StyledFormContainer = styled("div")({
  flex: 1,
  padding: "0 100px",
  "@media (max-width: 768px)": {
    zIndex: 10,
    backgroundColor: Colors.white,
    margin: "0 10px",
    padding: "0 20px",
    flex: 0,
    borderRadius: "20px",
    borderBottomRightRadius: "32px"
  },
  "& .form::-webkit-scrollbar": {
    display: "none" /* Chrome, Safari, Opera*/
  },
  "& .form": {
    "-ms-overflow-style": "none" /* IE and Edge */,
    scrollbarWidth: "none" /* Firefox */
  }
});

const StyledLogoMobile = styled("img")({
  display: "none",
  "@media (max-width: 768px)": {
    display: "block",
    width: "200px",
    margin: "30px auto"
  }
});

const StyledImageContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "50%"
});

const StyledCoverImage = styled("img")({
  width: "100%",
  height: "100vh",
  objectFit: "cover",
  borderBottomLeftRadius: "64px",
  "@media (max-width: 768px)": {
    display: "none"
  }
});

const StyledFormGroup = styled("div")({
  marginBottom: "16px",
  display: "flex",
  flexDirection: "column"
});

const StyledCheckboxContainer = styled("div")({
  display: "flex",
  alignItems: "center",
});

const StyledForm = styled(Form)({
  overflowY: "auto",
  "@media (max-width: 768px)": {
    maxHeight: "55vh"
  }
});

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { isPrivacyModalOpen, isTermsModalOpen, isSubmitting, errorMsg } = this.state;
    return (
      <>
        <StyledSignUpPage>
          <StyledLogoMobile src={logoMobile} alt="logo mobile" />
          <StyledFormContainer>
            <div style={styles.topContainer}>
              <h2 style={styles.title}>Sign up</h2>
              <p style={styles.description}>
                Enter details below to create a new account
              </p>
            </div>
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                password: "",
                salespersonCode: "",
                email: "",
                phone: "",
                terms: false
              }}
              data-test-id='signup-formik'
              validationSchema={signUpValidationSchema}
              onSubmit={this.handleSignUp}
            >
              {({ errors, values, handleChange, handleBlur, touched }) => (
                <StyledForm className="form">
                  <StyledFormGroup>
                    <Label htmlFor="firstName">First name</Label>
                    <Input
                      type="text"
                      name="firstName"
                      id="firstName"
                      value={values.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.firstName && touched.firstName}
                      placeholder="First name"
                    />
                    <ErrorMessage
                      data-test-id="error-message"
                      name="firstName"
                      component="div"
                      render={msg => <div style={styles.formError}>{msg}</div>}
                    />
                  </StyledFormGroup>

                  <StyledFormGroup>
                    <Label htmlFor="lastName">Last name</Label>
                    <Input
                      type="text"
                      name="lastName"
                      id="lastName"
                      value={values.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.lastName && touched.lastName}
                      placeholder="Last name"
                    />
                    <ErrorMessage
                      data-test-id="error-message"
                      name="lastName"
                      component="div"
                      render={msg => <div style={styles.formError}>{msg}</div>}
                    />
                  </StyledFormGroup>

                  <StyledFormGroup>
                    <Label htmlFor="salespersonCode">Salesperson Code</Label>
                    <Input
                      type="text"
                      name="salespersonCode"
                      id="salespersonCode"
                      value={values.salespersonCode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.salespersonCode && touched.salespersonCode}
                      placeholder="SC-12345678"
                    />
                    <ErrorMessage
                      data-test-id="error-message"
                      name="salespersonCode"
                      component="div"
                      render={msg => <div style={styles.formError}>{msg}</div>}
                    />
                  </StyledFormGroup>

                  <StyledFormGroup>
                    <Label htmlFor="email">Email</Label>
                    <Input
                      type="email"
                      name="email"
                      id="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.email && touched.email}
                      placeholder="youremail@gmail.com"
                    />
                    <ErrorMessage
                      data-test-id="error-message"
                      name="email"
                      component="div"
                      render={msg => <div style={styles.formError}>{msg}</div>}
                    />
                  </StyledFormGroup>

                  <StyledFormGroup>
                    <Label htmlFor="lastName">Password</Label>
                    <Input
                      name="password"
                      id="password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.password && touched.password}
                      placeholder="********"
                      showToggle
                    />
                    <ErrorMessage
                      data-test-id="error-message"
                      name="password"
                      component="div"
                      render={msg => <div style={styles.formError}>{msg}</div>}
                    />
                  </StyledFormGroup>

                  <StyledFormGroup>
                    <Label htmlFor="phone">Phone (optional)</Label>
                    <Input
                      type="text"
                      name="phone"
                      id="phone"
                      value={values.phone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="+1-555-453-6789"
                    />
                  </StyledFormGroup>

                  <StyledCheckboxContainer>
                    <Checkbox
                      name="terms"
                      id="terms"
                      value={values.terms.toString()}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <p style={{ color: "#0F172A" }}>
                      I agree with{" "}
                      <span
                        data-test-id="open-privacy-modal"
                        style={styles.hyperlink}
                        onClick={() => this.openModal("privacy")}
                      >
                        Privacy Policy
                      </span>{" "}
                      and{" "}
                      <span
                        data-test-id="open-terms-modal"
                        style={styles.hyperlink}
                        onClick={() => this.openModal("term")}
                      >
                        Terms and conditions
                      </span>
                    </p>
                  </StyledCheckboxContainer>
                  <ErrorMessage
                    data-test-id="error-message"
                    name="terms"
                    component="div"
                    render={msg => <div style={styles.formError}>{msg}</div>}
                  />

                  {errorMsg ? <p style={styles.formError}>{errorMsg}</p> : null}
                  <Button
                    variant="primary"
                    type="submit"
                    style={{ width: "100%", marginTop: "24px" }}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? <Spinner height="20px" width="20px" /> : "Sign up"}
                  </Button>
                </StyledForm>
              )}
            </Formik>
            <p style={{ textAlign: "center" }}>
              Already have an account?{" "}
              <Link to={"/login"} style={styles.cta}>
                Log in
              </Link>
            </p>
          </StyledFormContainer>

          <StyledImageContainer>
            <StyledCoverImage src={cover} alt="Seneca Dairy Systems" />
          </StyledImageContainer>
        </StyledSignUpPage>
        <TermsConditionsModal
          open={isTermsModalOpen}
          handleClose={() => this.closeModal("term")}
        />
        <PrivacyPolicyModal
          open={isPrivacyModalOpen}
          handleClose={() => this.closeModal("privacy")}
        />
      </>
    );
  }
}

const styles = {
  topContainer: {
    display: "flex",
    flexDirection: "column" as any,
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "24px"
  },
  title: {
    fontSize: "24px",
    fontWeight: "bold",
    color: Colors.textPrimary,
    marginBottom: "24px"
  },
  description: {
    fontSize: "16px",
    color: Colors.textSecondary
  },
  hyperlink: {
    color: "inherit",
    cursor: "pointer",
    textDecoration: "underline"
  },
  cta: {
    color: Colors.btnPrimary,
    cursor: "pointer",
    fontWeight: 700,
    textDecoration: "none"
  },
  formError: {
    color: Colors.error,
    fontSize: "12px",
    marginTop: "4px"
  }
};
