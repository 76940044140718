// Customizable Area Start
import React from "react";
import { styled } from "@mui/styles";

import ForgotPasswordController, {
  EResetStep,
  Props
} from "./ForgotPasswordController";
import { Colors } from "../../utilities/src/Colors";
import { cover, coverMobile, logoMobile } from "./assets";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { ResetPasswordForm } from "../../../components/src/email-account-registration/ResetPasswordForm";
import { EmailVerificationForm } from "../../../components/src/email-account-registration/EmailVerificationForm";

// Customizable Area End

const ForgotScreen = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: Colors.backgroundLight,
  "@media (max-width: 768px)": {
    flexDirection: "column",
    height: "100vh",
    backgroundImage: `url(${coverMobile})`,
    backgroundSize: "cover"
  }
});

const ImageContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "50%"
});

const CoverImage = styled("img")({
  width: "100%",
  height: "100vh",
  objectFit: "cover",
  borderBottomLeftRadius: "64px",
  "@media (max-width: 768px)": {
    display: "none"
  }
});

const LogoMobile = styled("img")({
  display: "none",
  "@media (max-width: 768px)": {
    display: "block",
    width: "200px",
    marginBottom: "30px"
  }
});

const TopContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "24px"
});

const Title = styled("h2")({
  fontSize: "24px",
  fontWeight: "bold",
  color: Colors.textPrimary,
  marginBottom: "24px"
});

const Description = styled("p")({
  fontSize: "16px",
  color: Colors.textSecondary,
  "@media (max-width: 768px)": {
    textAlign: "center"
  }
});

const FormContainer = styled("div")({
  flex: 1,
  padding: "0 100px",
  "@media (max-width: 768px)": {
    zIndex: 10,
    backgroundColor: Colors.white,
    margin: "0 10px",
    padding: "12px 24px",
    flex: 0,
    borderRadius: "8px",
    borderBottomRightRadius: "32px"
  }
});

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { resetStep, isSubmitting, errorMsg, isToastOpen } = this.state;

    return (
      <ForgotScreen>
        <LogoMobile src={logoMobile} alt="logo mobile" />
        {resetStep === EResetStep.EMAIL ? (
          <FormContainer>
            <TopContainer>
              <Title>Forgot Password?</Title>
              <Description>
                We'll send you a instructions on how you can retrieve your
                password.
              </Description>
            </TopContainer>

            <EmailVerificationForm
              handleVerifyEmail={this.handleVerifyEmail}
              isSubmitting={isSubmitting}
              error={errorMsg}
            />
          </FormContainer>
        ) : (
          <FormContainer>
            <TopContainer>
              <Title>Password creation</Title>
              <Description>Create new password</Description>
            </TopContainer>

            <ResetPasswordForm
              handleResetPassword={this.handleResetPassword}
              isSubmitting={isSubmitting}
            />
          </FormContainer>
        )}
        <ImageContainer>
          <CoverImage src={cover} alt="Seneca Dairy Systems" />
        </ImageContainer>

        <Snackbar
          open={isToastOpen}
          autoHideDuration={3000}
          onClose={this.closeToast}
        >
          <Alert
            onClose={this.closeToast}
            severity="success"
            color="success"
            sx={{ width: "100%" }}
          >
            Password successfully reset!
          </Alert>
        </Snackbar>
      </ForgotScreen>
    );
  }
}
