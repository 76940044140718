// Customizable Area Start
import invert from 'invert-color';

/* get colors */
export const Colors = {
  white: '#FFFFFF',
  gray: '#94A3B8',
  textPrimary: '#0F172A',
  textSecondary: '#334155',
  textTertiary: '#64748B',
  btnPrimary: '#005C97',
  btnSecondary: '#CCDEEA',
  backgroundLight: "#F1F5F9",
  error: '#DC2626',
  success: '#34D399',
  bgPrimary: '#E5EFF5'
}
// Customizable Area End