// App.js - WEB
import React from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { BuilderProvider } from '@builder/component-library';
import { Outlet } from 'react-router-dom';
import { WebRoutesGenerator } from '../../components/src/NavigationRouteWrapper/Web';
import HomeScreen from '../../components/src/HomeScreen';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import EmailAccountLogin from "../../blocks/email-account-registration/src/EmailAccountLogin.web";
import ForgotPassword from "../../blocks/email-account-registration/src/ForgotPassword.web";
import Dashboard from "../../blocks/dashboard/src/Dashboard.web";


const routeMap = {
  SignUp: {
    component: EmailAccountRegistration,
    path: '/sign-up',
  },
  Login: {
    component: EmailAccountLogin,
    path: '/login',
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/forgot-password'
  },
  Home: {
    component: Dashboard,
    path: '/',
    exact: true,
  },
  Catalog: {
    component: HomeScreen,
    path: '/catalog',
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

const defaultAnalytics = firebaseAPI.analytics();
defaultAnalytics.logEvent('APP_Loaded');

function App() {
  return (
    <BuilderProvider>
      <View style={{height: '100vh', width: '100vw'}}>
        <WebRoutesGenerator routeMap={routeMap} />
        <Outlet />
        <AlertBlock />
      </View>
    </BuilderProvider>
  );
}

export default App;