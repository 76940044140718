import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { createRequest } from "../../../components/src/utils/api";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export enum EResetStep {
  EMAIL = "EMAIL",
  PASSWORD = "PASSWORD"
}

export interface S {
  // Customizable Area Start
  resetStep: EResetStep;
  isSubmitting: boolean;
  errorMsg: string;
  resetToken: string;
  isToastOpen: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  verifyEmailRequestId: any;
  resetPasswordRequestId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      resetStep: EResetStep.EMAIL,
      isSubmitting: false,
      errorMsg: "",
      resetToken: "",
      isToastOpen: false,
      // Customizable Area End
    };

    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (responseJson.error) {
          this.setState({
            isSubmitting: false,
            errorMsg: responseJson.error
          });
          return;
        }

        if (apiRequestCallId === this.verifyEmailRequestId) {
          this.setState({
            isSubmitting: false,
            resetStep: EResetStep.PASSWORD,
            resetToken: responseJson.token
          });
        }

        if (apiRequestCallId === this.resetPasswordRequestId) {
          this.setState({ isToastOpen: true })
          this.props.navigation.navigate("Login");
        }
      }
    }
  }

  handleVerifyEmail = ({ email }: { email: string }) => {
    this.setState({ isSubmitting: true })

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.verifyEmailRequestId = requestMessage.messageId;

    createRequest({
      requestMsg: requestMessage,
      endPoint: configJSON.resetPasswordEndpoint,
      method: "POST",
      body: JSON.stringify({
        email: email
      })
    });
  };

  handleResetPassword = (values: any) => {
    this.setState({ isSubmitting: true })

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.resetPasswordRequestId = requestMessage.messageId;

    const payload = {
      account: {
        password: values.password,
        password_confirmation: values.password_confirmation
      },
      token: this.state.resetToken
    };

    createRequest({
      requestMsg: requestMessage,
      endPoint: `${configJSON.resetPasswordEndpoint}?token=${this.state.resetToken}`,
      method: "PATCH",
      body: JSON.stringify(payload)
    });
  };

  closeToast = () => {
    this.setState({ isToastOpen: false })
  }
  // Customizable Area End
}
