import { resetPasswordValidationSchema } from "./types";
import { useFormik } from "formik";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { Spinner } from "../ui/spinner";
import { Link } from "react-router-dom";
import { Colors } from "../../../blocks/utilities/src/Colors";
import React from "react";
import { styled } from "@mui/styles";

const StyledFormGroup = styled("div")({
  marginBottom: "24px",
  display: "flex",
  flexDirection: "column",
});

const styles = {
  formError: {
    color: Colors.error,
    fontSize: "12px",
    marginTop: "4px",
  },
  invalid: {
    fontSize: 12,
    color: Colors.error
  },
  valid: {
    fontSize: 12,
    color: Colors.success
  }
};

interface ResetPasswordPayload {
  [key: string]: string
}

interface ResetPasswordProps {
  handleResetPassword: (values: ResetPasswordPayload) => void;
  isSubmitting: boolean
}

export const ResetPasswordForm = ({
  handleResetPassword,
  isSubmitting
}: ResetPasswordProps) => {
  const { values, errors, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: resetPasswordValidationSchema,
    onSubmit: handleResetPassword,
  });

  const password = values.password;

  const passwordCriteria = {
    length: password.length >= 8,
    uppercase: /[A-Z]/.test(password),
    lowercase: /[a-z]/.test(password),
    number: /[0-9]/.test(password),
    specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
  };

  return (
    <form onSubmit={handleSubmit}>
      <StyledFormGroup>
        <Label htmlFor="password">Password</Label>
        <Input
          name="password"
          id="password"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={!!errors.password}
          placeholder="********"
          showToggle
        />
        <p style={styles.formError}>{errors.password}</p>

        <div>
          <p style={passwordCriteria.length ? styles.valid : styles.invalid}>
            At least 8 characters
          </p>
          <p style={passwordCriteria.uppercase ? styles.valid : styles.invalid}>
            At least one uppercase letter
          </p>
          <p style={passwordCriteria.lowercase ? styles.valid : styles.invalid}>
            At least one lowercase letter
          </p>
          <p style={passwordCriteria.number ? styles.valid : styles.invalid}>
            At least one number
          </p>
          <p style={passwordCriteria.specialChar ? styles.valid : styles.invalid}>
            At least one special character
          </p>
        </div>
      </StyledFormGroup>

      <StyledFormGroup>
        <Label htmlFor="confirmPassword">Confirm password</Label>
        <Input
          name="confirmPassword"
          id="confirmPassword"
          value={values.confirmPassword}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={!!errors.confirmPassword}
          placeholder="********"
          showToggle
        />
        <p style={styles.formError}>{errors.confirmPassword}</p>
      </StyledFormGroup>

      <Button variant="primary" type="submit" style={{width: "100%"}} disabled={isSubmitting}>
        {isSubmitting ? <Spinner height="20px" width="20px" /> : "Create new password"}
      </Button>

      <Link
        to="/login"
        style={{
          display: "block",
          color: Colors.btnPrimary,
          fontWeight: 600,
          textDecoration: "none",
          marginTop: "24px",
          textAlign: "center",
        }}
      >
        Back to Sign in
      </Link>
    </form>
  );
};
