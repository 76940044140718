import React from "react";
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles({
  label: {
    display: 'block',
    fontWeight: 700,
    fontSize: 14,
    color: '#334155',
    marginBottom: 8,
    letterSpacing: '0.5px',
  }
})
const Label = React.forwardRef<HTMLLabelElement, any>(({className, ...props}, ref) => {
  const styles = useStyles();
  return (
    <label className={styles.label} ref={ref} {...props} />
  );
})

export {Label};

Label.displayName = 'Label';