import {Message} from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import {runEngine} from "../../../framework/src/RunEngine";

export const handleTestApiCall = (
  messageApi: Message,
  bodyData?: object | string[] | number[] | object[],
) => {
  messageApi.addData(
    getName(MessageEnum.RestAPIResponceDataMessage),
    messageApi.messageId,
  );

  messageApi.addData(getName(MessageEnum.RestAPIResponceSuccessMessage), {
    error: ["error message"],
  });

  runEngine.sendMessage("Unit Test", messageApi);

  messageApi.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    "/endpoint",
  );

  messageApi.addData(
    getName(MessageEnum.RestAPIResponceSuccessMessage),
    bodyData,
  );

  runEngine.sendMessage("Unit Test", messageApi);
}

export const createRequest = (request: {
  requestMsg: Message;
  endPoint: string;
  header?: object;
  method: string;
  token?: string;
  body?: string | FormData;
  isFormDataRequest?: boolean;
}) => {
  const {
    requestMsg: apiMessage,
    endPoint: apiEndPoint,
    header: initHeader,
    method: apiMethod,
    token: apiToken,
    body: bodyRequest,
    isFormDataRequest,
  } = request;

  const convertHeader = isFormDataRequest
    ? {
      Token: apiToken ?? undefined,
    }
    : {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Token": apiToken ?? undefined,
      ...initHeader,
    };

  apiMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    apiEndPoint,
  );

  apiMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(convertHeader),
  );

  apiMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    apiMethod,
  );

  bodyRequest &&
  apiMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    bodyRequest,
  );

  runEngine.sendMessage(apiMessage.id, apiMessage);
}
