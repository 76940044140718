export const PROJECT_STATUSES: {
  key: string;
  title: string;
}[] = [
  { key: "sent", title: "Sent" },
  { key: "in_progress", title: "In Progress" },
  { key: "completed", title: "Completed" },
];

export const BARN_TYPE: {
  key: string;
  title: string;
}[] = [
  { key: "custom", title: "Custom Quote" },
  { key: "quick", title: "Quick Quote" },
];

export const PROJECT_TYPE: {
  key: string;
  title: string;
}[] = [
  { key: "four_row", title: "4-Row Barn" },
  { key: "six_row", title: "6-Row Barn" },
  { key: "equipment", title: "Equipment" },
];
