import React from "react"
import { makeStyles } from "@mui/styles";
import { Colors } from "../../../blocks/utilities/src/Colors";
import { fourRowBarn, sixRowBarn } from "../../../blocks/dashboard/src/assets";
import { Button } from "../ui/button";
// @ts-ignore
import { BaseSlider } from "./BaseSlider";

const useStyles = makeStyles({
  sectionTitle: {
    fontSize: "12px",
    color: Colors.textTertiary,
    fontWeight: 600,
    textTransform: "uppercase",
    marginBottom: "24px",
  },
  barnContainer: {
    position: "relative",
    display: "flex",
    gap: "28px",
  },
  barnItem: {},
  barnImage: {
    width: "100%",
    height: "auto",
  },
  barnText: {
    margin: "8px 0",
  },
  barnTitle: {
    color: Colors.textPrimary,
    fontSize: "14px",
    fontWeight: 700,
    marginBottom: "4px",
  },
  barnDescription: {
    color: Colors.textTertiary,
    fontSize: "14px",
  },
});

export default function BarnType() {
  const styles = useStyles();

  const barnTypes = [
    {
      key: "4-row",
      title: "4-Row barn",
      description: "lorem ipsum",
      imageUrl: fourRowBarn,
    },
    {
      key: "6-row",
      title: "6-Row barn",
      description: "lorem ipsum",
      imageUrl: sixRowBarn,
    },
  ];

  return (
    <div>
      <h3 className={styles.sectionTitle}>Barn type</h3>
      <div className={styles.barnContainer}>
        <BaseSlider type='barn'>
          {barnTypes.map((barn) => (
            <div className={styles.barnItem} key={barn.key}>
              <img
                src={barn.imageUrl}
                alt={barn.title}
                className={styles.barnImage}
              />
              <div className={styles.barnText}>
                <h4 className={styles.barnTitle}>{barn.title}</h4>
                <p className={styles.barnDescription}>{barn.description}</p>
              </div>
              <Button
                variant="primary"
                style={{ padding: "8px 12px", marginTop: "8px" }}
              >
                Customize
              </Button>
            </div>
          ))}
        </BaseSlider>
      </div>
    </div>
  );
}