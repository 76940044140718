// Customizable Area Start
import React from "react";
import Grid from "@mui/material/Grid";
import AddClientModalController, {
  Props,
  // @ts-ignore
} from "./AddClientModalController";
import { ModalCore } from "../ui/modal";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { Formik, Form } from "formik";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { styled } from "@mui/styles";
import { Colors } from "../../../blocks/utilities/src/Colors";

// Customizable Area End

const initialFormValues = {
  fullName: "",
  email: "",
  phone: "",
  address: "",
  zip: "",
  city: "",
  state: "",
};

const StyledHorizontalLines = styled("div")({
  width: "100%",
  height: "1px",
  backgroundColor: "#E5E5E5",
  margin: "24px 0",
});

const StyledButtonContainer = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  gap: "16px",
  marginTop: "24px",
});

const AddAddressBox = styled("div")({
  margin: "48px 0",
  display: "flex",
  gap: "8px",
});

const AddAddressText = styled("p")({
  fontWeight: 700,
  color: Colors.btnPrimary,
});

export default class AddClientModal extends AddClientModalController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { open, closeModal } = this.props;
    return (
      <ModalCore open={open} handleClose={closeModal} title="New Client">
        <>
          <StyledHorizontalLines />
          <p
            style={{
              color: Colors.textTertiary,
              fontSize: "14px",
              marginBottom: "16px",
            }}
          >
            Create new client and and client details
          </p>

          <Formik
            initialValues={initialFormValues}
            onSubmit={this.createNewClient}
          >
            {({ values, handleChange, handleBlur }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <div>
                      <Label htmlFor="fullName">Full name</Label>
                      <Input
                        type="text"
                        name="fullName"
                        id="fullName"
                        placeholder="Full name"
                        value={values.fullName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div>
                      <Label htmlFor="email">Email</Label>
                      <Input
                        type="text"
                        name="email"
                        id="email"
                        placeholder="Email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div>
                      <Label htmlFor="phone">Phone</Label>
                      <Input
                        type="text"
                        name="phone"
                        id="phone"
                        placeholder="Phone"
                        value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div>
                      <Label htmlFor="address">Address</Label>
                      <Input
                        type="text"
                        name="address"
                        id="address"
                        placeholder="Address"
                        value={values.address}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div>
                      <Label htmlFor="zip">Zip</Label>
                      <Input
                        type="text"
                        name="zip"
                        id="zip"
                        placeholder="Zip"
                        value={values.zip}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div>
                      <Label htmlFor="city">City</Label>
                      <Input
                        type="text"
                        name="city"
                        id="city"
                        placeholder="City"
                        value={values.city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div>
                      <Label htmlFor="state">State</Label>
                      <Input
                        type="text"
                        name="state"
                        id="state"
                        placeholder="State"
                        value={values.state}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </Grid>
                </Grid>

                <AddAddressBox>
                  <AddCircleOutlineIcon style={{ color: Colors.btnPrimary }} />
                  <AddAddressText>Add another address</AddAddressText>
                </AddAddressBox>
                <StyledHorizontalLines />

                <StyledButtonContainer>
                  <Button variant="secondary" onClick={closeModal}>
                    Cancel
                  </Button>
                  <Button variant="primary" type="submit">
                    Create
                  </Button>
                </StyledButtonContainer>
              </Form>
            )}
          </Formik>
        </>
      </ModalCore>
    );
  }
}
