import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
// @ts-ignore
import {createRequest} from "../../../components/src/utils/api";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  isPrivacyModalOpen: boolean;
  isTermsModalOpen: boolean;
  isSubmitting: boolean;
  errorMsg: string
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  signUpRequestId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      isPrivacyModalOpen: false,
      isTermsModalOpen: false,
      isSubmitting: false,
      errorMsg: ''
      // Customizable Area End
    };

    // Customizable Area Start

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (responseJson.errors) {
          this.setState({
            isSubmitting: false,
            errorMsg: responseJson.errors[0].account
          })
          return
        }

        if (apiRequestCallId === this.signUpRequestId) {
          this.setState({ isSubmitting: false })
          localStorage.setItem('token', responseJson.meta?.token)
          this.props.navigation.navigate('Home')
          // Handle Sign Up Response
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleSignUp = (values: any) => {
    this.setState({ isSubmitting: true })

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.signUpRequestId = requestMessage.messageId;

    const payload = {
      data: {
        type: "email_account",
        attributes: {
          first_name: values.firstName,
          last_name: values.lastName,
          email: values.email,
          full_phone_number: values.phone,
          password: values.password,
          password_confirmation: values.password,
        }
      }
    }

    createRequest({
      requestMsg: requestMessage,
      endPoint: configJSON.signUpEndpoint,
      method: "POST",
      body: JSON.stringify(payload),
    })
  }

  openModal = (modalType: string) => {
    if (modalType === "privacy") {
      this.setState({ isPrivacyModalOpen: true });
    } else {
      this.setState({ isTermsModalOpen: true });
    }
  }

  closeModal = (modalType: string) => {
    if (modalType === "privacy") {
      this.setState({ isPrivacyModalOpen: false });
    } else {
      this.setState({ isTermsModalOpen: false });
    }
  }

  // Customizable Area End
}
