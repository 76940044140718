import React from "react";
import {styled} from "@mui/styles";
import {ModalCore} from "../ui/modal";
import {Colors} from "../../../blocks/utilities/src/Colors";
import {Button} from "../ui/button";

interface Props {
  open: boolean;
  handleClose: () => void;
}

const StyledHeader = styled('h3')({
  fontSize: '1.5rem',
  fontWeight: 700,
  color: Colors.textPrimary,
  "@media (max-width: 768px)": {
    fontSize: '1.25rem',
  }
})

const commonStyles = {
  color: Colors.textPrimary,
  fontSize: '1rem',
  lineHeight: "1.35",
  "@media (max-width: 768px)": {
    fontSize: '0.875rem',
  }
};

const StyledParagraph = styled('p')(commonStyles);

const StyledUl = styled('ul')(commonStyles);

const StyledLi = styled('li')(commonStyles);

const HorizontalLines = styled("div")({
  width: "100%",
  height: "1px",
  backgroundColor: "#E5E5E5",
  margin: "24px 0",
});

const ButtonContainer = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  gap: "16px",
  marginTop: "24px",
});

export const TermsConditionsModal = ({open, handleClose}: Props) => {
  return (
    <ModalCore open={open} handleClose={handleClose}>
      <div>
        <StyledHeader>Terms and Conditions</StyledHeader>
        <StyledParagraph>Welcome to <strong>BarnDesigner</strong>! {""}
          These Terms and Conditions (“Terms”) govern your use of the BarnDesigner platform (the “Platform”), services,
          and tools.
          By accessing or using the Platform, you agree to comply with and be bound by these Terms.
          If you do not agree to these Terms, you may not access or use our services.
        </StyledParagraph>

        <StyledParagraph>1. Definitions</StyledParagraph>
        <StyledUl>
          <StyledLi>“Platform” refers to the BarnDesigner website, mobile app, and any associated services or
            tools provided.
          </StyledLi>
          <StyledLi>“User” refers to any individual or entity accessing the Platform.</StyledLi>
          <StyledLi>“Content” includes text, images, designs, videos, and other materials available on the
            Platform.
          </StyledLi>
        </StyledUl>

        <StyledParagraph>2. Eligibility</StyledParagraph>
        <StyledUl>
          <StyledLi>You must be at least 18 years old or have parental/guardian consent to use the Platform.</StyledLi>
          <StyledLi>By registering, you represent and warrant that the information you provide is accurate, complete, and
            up-to-date.
          </StyledLi>
        </StyledUl>

        <StyledParagraph>3. User Accounts</StyledParagraph>
        <StyledUl>
          <StyledLi>Users must create an account to access certain features of the Platform.</StyledLi>
          <StyledLi>You are responsible for maintaining the confidentiality of your account credentials and for all activities
            under your account.
          </StyledLi>
          <StyledLi>Notify us immediately of any unauthorized use or security breach.</StyledLi>
        </StyledUl>

        <StyledParagraph>4. Permitted Use</StyledParagraph>
        <StyledUl>
          <StyledLi> The Platform is provided for creating and designing barns for personal or business purposes.</StyledLi>
          <StyledLi> Users agree not to: </StyledLi>
          <StyledUl>
            <StyledLi>Misuse the Platform or engage in unlawfStyledUl activities.</StyledLi>
            <StyledLi>Copy, distribute, or exploit any part of the Platform without prior written consent.</StyledLi>
            <StyledLi>Upload or transmit viruses or malicious code.</StyledLi>
          </StyledUl>
        </StyledUl>

        <HorizontalLines />

        <ButtonContainer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Accept
          </Button>
        </ButtonContainer>
      </div>
    </ModalCore>
  );
};
