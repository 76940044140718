import * as Yup from "yup";

export const signUpValidationSchema = Yup.object({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  password: Yup.string().required("Password is required"),
  salespersonCode: Yup.string().nullable(),
  email: Yup.string().email("Invalid email address").required("Email is required"),
  phone: Yup.string().nullable(),
  terms: Yup.boolean().oneOf([true], "You must accept the terms and conditions"),
});

export const loginValidationSchema = Yup.object({
  email: Yup.string().email("Invalid email address").required("Email is required"),
  password: Yup.string().required("Password is required"),
});

export const forgotPasswordValidationSchema = Yup.object({
  email: Yup.string().email("Invalid email address").required("Email is required"),
});

export const resetPasswordValidationSchema = Yup.object({
  password: Yup.string().required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Password confirmation is required"),
});