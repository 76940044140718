import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Colors } from "../../../../blocks/utilities/src/Colors";

const useStyles = makeStyles({
  inputWrapper: {
    position: "relative",
    width: "100%",
  },
  input: {
    width: "100%",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    padding: "12px 8px",
    display: "flex",
    "&::placeholder": {
      color: "#94A3B8",
    },
  },
  inputInvalid: {
    border: `1px solid ${Colors.error}`,
  },
  iconBlock: {
    position: "absolute",
    top: "50%",
    right: "10px",
    transform: "translateY(-50%)",
    cursor: "pointer",
    color: Colors.textPrimary,
  },
});

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  isInvalid?: boolean;
  showToggle?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ isInvalid, showToggle, ...props }, ref) => {
    const styles = useStyles();
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const toggleVisibility = () => {
      setIsPasswordVisible(!isPasswordVisible);
    };

    return (
      <div className={styles.inputWrapper}>
        <input
          type={isPasswordVisible && showToggle ? "text" : "password"}
          className={`${styles.input} ${isInvalid ? styles.inputInvalid : ""}`}
          ref={ref}
          {...props}
        />
        {showToggle && (
          <span onClick={toggleVisibility} className={styles.iconBlock}>
            {isPasswordVisible ? (
              <VisibilityIcon style={{ fontSize: "16px" }} />
            ) : (
              <VisibilityOffIcon style={{ fontSize: "16px" }} />
            )}
          </span>
        )}
      </div>
    );
  }
);

export { Input };

Input.displayName = "Input";
