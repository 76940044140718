import React, {useState} from "react"
import { makeStyles } from "@mui/styles";
import { Colors } from "../../../blocks/utilities/src/Colors";
import {
  freeStalls,
  ventilation,
  gates,
  headlocks,
  cowMats,
} from "../../../blocks/dashboard/src/assets";
import { Button } from "../ui/button";
import {BaseSlider} from "./BaseSlider";
// @ts-ignore
import AddClientModal from "./AddClientModal";

const useStyles = makeStyles({
  sectionTitle: {
    fontSize: "12px",
    color: Colors.textTertiary,
    fontWeight: 600,
    textTransform: "uppercase",
    marginBottom: "24px",
  },
  categoryContainer: {
    display: "flex",
    gap: "28px",
  },
  categoryItem: {},
  categoryImage: {
    width: "100%",
    height: "auto",
  },
  categoryText: {
    margin: "8px 0",
  },
  categoryTitle: {
    color: Colors.textPrimary,
    fontSize: "14px",
    fontWeight: 700,
    marginBottom: "4px",
  },
  categoryDescription: {
    color: Colors.textTertiary,
    fontSize: "14px",
  },
});

export default function ProductCategories() {
  const styles = useStyles();

  const categories = [
    {
      key: "ventilation",
      title: "Ventilation",
      description: "lorem ipsum",
      imageUrl: ventilation,
    },
    {
      key: "free-stalls",
      title: "Free Stalls",
      description: "lorem ipsum",
      imageUrl: freeStalls,
    },
    {
      key: "gates",
      title: "Gates/Panels",
      description: "lorem ipsum",
      imageUrl: gates,
    },
    {
      key: "headlocks",
      title: "Headlocks/Slant bars/Feed Rail",
      description: "lorem ipsum",
      imageUrl: headlocks,
    },
    {
      key: "cow-mats",
      title: "Cow Mats",
      description: "lorem ipsum",
      imageUrl: cowMats,
    },
  ];

  const [addModalOpen, setAddModalOpen] = useState(false)

  const handleCustomizeCategory = () => {
    setAddModalOpen(true)
  }

  const closeModal = () => {
    setAddModalOpen(false)
  }

  return (
    <div>
      <h3 className={styles.sectionTitle}>product categories process</h3>
      <div className={styles.categoryContainer}>
        <BaseSlider type='category'>
          {categories.map((category) => (
            <div className={styles.categoryItem} key={category.key}>
              <img
                src={category.imageUrl}
                alt={category.title}
                className={styles.categoryImage}
              />
              <div className={styles.categoryText}>
                <h4 className={styles.categoryTitle}>{category.title}</h4>
                <p className={styles.categoryDescription}>
                  {category.description}
                </p>
              </div>
              <Button
                variant="primary"
                style={{ padding: "8px 12px", marginTop: "8px" }}
                onClick={handleCustomizeCategory}
              >
                Customize
              </Button>
            </div>
          ))}
        </BaseSlider>
      </div>

      <AddClientModal open={addModalOpen} closeModal={closeModal} />
    </div>
  );
}
