import React from "react";
import {makeStyles} from '@mui/styles';
import {Colors} from "../../../../blocks/utilities/src/Colors";

const useStyles = makeStyles({
  button: {
    borderRadius: '8px',
    border: '1px solid #CBD5E1',
    padding: '16px 20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: 700,
    cursor: 'pointer'
  },
  primary: {
    backgroundColor: Colors.btnPrimary,
    color: Colors.white
  },
  secondary: {
    backgroundColor: Colors.btnSecondary,
    color: Colors.btnPrimary
  }
})

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant: "primary" | "secondary",
  children: React.ReactNode
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({variant = "primary", children, ...props}, ref) => {
  const styles = useStyles();
  return (
    <button className={`${styles.button} ${styles[variant]}`} ref={ref} {...props}>
      {children}
    </button>
  );
})

export {Button};

Button.displayName = 'Button';