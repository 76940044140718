import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
// Customizable Area End

export interface Props {
  open: boolean,
  closeModal: () => void
}

export interface S {
  // Customizable Area Start

  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class AddClientModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  verifyEmailRequestId: any;
  resetPasswordRequestId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      // Customizable Area End
    };
  }

  // Customizable Area Start
  createNewClient = () => {}
  // Customizable Area End
}
